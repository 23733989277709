<template>
	<page-container class="index" :page="page">
		<heading-image v-if="page.headingImage" type="large">
			<hero-image>
				<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
					<p class="hero-title">
						{{ defaults[locale].homepage.headingImageTitle }}
					</p>
				</div>
				<picture>
					<source
						v-if="page.headingImagePortraitWebp"
						:srcset="page.headingImagePortraitWebp"
						media="(max-width: 500px)"
						type="image/webp"
					/>
					<source
						v-if="page.headingImagePortrait"
						:srcset="page.headingImagePortrait"
						media="(max-width: 500px)"
					/>
					<source :srcset="page.headingImageWebp" type="image/webp" />
					<source :srcset="page.headingImage" />
					<img :src="page.headingImage" :alt="page.headingImageAlt" />
				</picture>
			</hero-image>
		</heading-image>

		<section v-if="events.length" class="section-events-intro">
			<events-intro-block :items="events.slice(0, 4)" />
		</section>

		<main-content :class="events.length && 'has-events'" class="content-section-index bg-1 pb-0">
			<div class="grid main-intro">
				<div data-aos="fade-right" data-aos-duration="1000" data-aos-once="true" class="intro-image">
					<picture>
						<source :srcset="defaults[locale].homepage.introImageWebp" type="image/webp" />
						<source :srcset="defaults[locale].homepage.introImage" />
						<img
							:src="defaults[locale].homepage.introImage"
							:alt="defaults[locale].homepage.introImageAlt"
							loading="lazy"
						/>
					</picture>
				</div>
				<div data-aos="fade-left" data-aos-duration="1000" data-aos-once="true" class="intro-text">
					<h2 class="subtitle light">
						{{ page.subtitle }}
					</h2>
					<h1>{{ page.title || page.header }}</h1>
					<div v-parse-links v-html="page.content" />
				</div>
			</div>
		</main-content>

		<promoblocks :items="promoblocksData" />

		<section class="content-section section-events align-center bg-2">
			<div class="row">
				<div class="columns column12">
					<h2 v-if="defaults[locale].homepage.sectionEventsSubtitle" class="subtitle light">
						{{ defaults[locale].homepage.sectionEventsSubtitle }}
					</h2>
					<h1>{{ defaults[locale].homepage.sectionEventsTitle }}</h1>
					<div v-parse-links class="events-content" v-html="defaults[locale].homepage.sectionEventsContent" />
				</div>
			</div>
		</section>

		<section v-if="defaults[locale].homepage.promotionBackgroundImage" class="section-promotion">
			<div class="row">
				<div class="columns column12 align-center">
					<div class="promotion-block">
						<div class="content">
							<h2 class="subtitle">
								{{ defaults[locale].homepage.promotionsSubtitle }}
							</h2>
							<h2>{{ defaults[locale].homepage.promotionsHeader }}</h2>
							<div v-parse-links v-html="defaults[locale].homepage.promotionsContent" />
						</div>
						<picture>
							<source
								:srcset="defaults[locale].homepage.promotionBackgroundImageWebp"
								type="image/webp"
							/>
							<source :srcset="defaults[locale].homepage.promotionBackgroundImage" />
							<img
								class="promotions-image"
								:src="defaults[locale].homepage.promotionBackgroundImage"
								loading="lazy"
							/>
						</picture>
					</div>
				</div>
			</div>
		</section>

		<contentblocks :items="page.contentblocks" class="section-contentblocks bg-3">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2 class="subtitle light">
							{{ defaults[locale].homepage.sectionContentblocksSubtitle }}
						</h2>
						<h2>{{ defaults[locale].homepage.sectionContentblocksTitle }}</h2>
					</div>
				</div>
			</template>
		</contentblocks>

		<section v-if="defaults[locale].homepage.contactHeader" class="content-section section-contact contact-info">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.contactSubtitle }}
					</h2>
					<h2>
						{{ defaults[locale].homepage.contactHeader }}
					</h2>
					<div v-parse-links v-html="defaults[locale].homepage.contactContent" />
				</div>
			</div>
			<div class="row">
				<div class="columns column12">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2434.046500733374!2d4.8822262770612985!3d52.405833444675345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6099501be2225%3A0x5161e3d1a57f6759!2sLagerwal!5e0!3m2!1sen!2snl!4v1690357433795!5m2!1sen!2snl"
						width="100%"
						height="500"
						frameborder="0"
						style="border: 0"
						allowfullscreen=""
						aria-hidden="false"
						tabindex="0"
					/>
					<div class="contact-info">
						<p>
							<img src="/logo-gradient.png" />
							{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}<br />
							{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}<br />
							<a
								v-if="defaults[locale].website.phone"
								:href="`tel:${$phoneRaw(defaults[locale].website.phone)}`"
							>
								<font-awesome-icon class="icon" icon="fal fa-phone" size="1x" />
								{{ defaults[locale].website.phone }} </a
							><br />
							<a v-if="defaults[locale].website.email" :href="`mailto:${defaults[locale].website.email}`">
								<font-awesome-icon class="icon" icon="fal fa-envelope" size="1x" />
								{{ defaults[locale].website.email }}
							</a>
						</p>
					</div>
				</div>
			</div>
		</section>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});

const { data: events } = await useWebsiteFetch('events', {
	query: { language: locale.value },
	key: `${locale.value}/events`,
});
</script>

<style lang="scss" scoped>
.hero-content {
	position: absolute;
	top: 50%;
	transform: translateY(calc(-50% - 2vh));
	width: 100%;
	z-index: 5;
	animation: fade-from-bottom 2.5s ease;
	text-align: center;

	.hero-title {
		font-weight: 900;
		color: #fff;
		font-size: 76px;
		margin: auto;
		max-width: 900px;
		text-transform: uppercase;
		line-height: normal;
	}
}

.section-events-intro {
	position: relative;
}

.main-intro {
	grid-template-columns: 1fr 1fr;
	gap: 5%;
	width: 100%;
	overflow: hidden;

	.intro-text {
		max-width: 600px;
		padding-right: 15px;
	}
}

.section-events {
	padding-bottom: 350px;

	.events-content {
		max-width: 650px;
		margin: auto;
	}
}

.section-promotion {
	padding-bottom: 450px;

	.columns {
		position: relative;
	}

	.promotion-block {
		position: absolute;
		z-index: 10;
		width: 100%;
		top: -300px;
		left: 0;
		right: 0;
		color: #fff;
		padding: 150px 50px;
		max-height: 650px;

		.content {
			position: relative;
			max-width: 800px;
			z-index: 2;
			margin: auto;
		}
	}

	.promotions-image {
		position: absolute;
		object-fit: cover;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	h2:not(.subtitle) {
		color: #fff;
	}
}

.section-contentblocks {
	.blocks-header {
		margin-bottom: 70px;
	}
}

.section-contact {
	.columns {
		position: relative;
	}

	.row:first-child {
		padding-bottom: 50px;
	}

	.contact-info {
		position: absolute;
		background-color: #fff;
		padding: 35px;
		right: 55px;
		top: 40px;

		img {
			max-width: 150px;
			display: block;
			margin-bottom: 10px;
		}
	}
}

@keyframes fade-from-bottom {
	0% {
		opacity: 0;
		transform: translateY(calc(0% - 2vh));
	}

	66% {
		opacity: 0;
	}

	100% {
		opacity: 1;
		transform: translateY(calc(-50% - 2vh));
	}
}

@media (max-width: 1080px) {
	.main-intro {
		grid-template-columns: 100%;

		.intro-text {
			padding: 0 50px;
			margin: auto;
		}
	}

	.intro-image {
		display: none;
	}
}

@media (max-width: 880px) {
	.hero-content {
		top: 40%;
		font-size: 15px;
		padding: 0 10px;

		.hero-title {
			font-size: 45px;
		}
	}

	.section-promotion {
		padding-bottom: 250px;

		.promotion-block {
			padding: 50px 30px;
		}
	}

	.section-promotion .promotions-image {
		position: absolute;
	}

	.section-reservations .reservation-form > div {
		width: 100%;
	}
}

@media (max-width: 500px) {
	.section-promotion {
		padding-bottom: 300px;
	}
}
</style>
